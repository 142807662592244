.slick-slider {
    margin-right: -30px;
}

.carousel_item {
    padding-right: 30px;

    .carousel_img {
        border-radius: 12px;
        height: 380px;

        @media only screen and (max-width: 767px) {
            height: auto;
        }

        img {
            border-radius: 12px;
            width: 100%;
            height: 100%
        }
    }

    h3 {
        @apply text-xl lg:text-[26px] font-semibold text-[#272728] mt-5 lg:mt-[30px] leading-6 lg:leading-8 mb-4 lg:mb-5;
    }

    p {
        @apply text-[15px] lg:text-base text-[#9E9FA5] leading-6;
    }
}