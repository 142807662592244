.header {

    .header_nav {
        @apply gap-[44px] text-base font-medium leading-6 text-white flex items-center;

        .active {
            color: #FF8677;
        }
    }

    &.scroll_down {
        backdrop-filter: blur(3px);
        box-shadow: 0 0 15px 0 rgba(216,236,254,.75);

        .header_nav {
            @apply text-black;
        }

        .header_mobile_menu {
            svg {
                fill: black;
            }
        }
    }
}


.mobile_nav {
    @apply text-base font-medium leading-6 text-black mb-10;

    a {
        @apply block mb-4;
    }

    .active {
        color: #FF8677;
    }
}

.mobile-header {
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
}