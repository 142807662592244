.button {
    @apply rounded-[50px] text-base font-medium text-white leading-7 px-9 py-2.5 h-[52px];
    font-family: 'Manrope', sans-serif;
    background-color: #FC6D2B !important;
}

.contact-button {
    
    button {
        @apply h-[68px] w-full font-['Poppins',sans-serif] font-semibold;
    }
}

.secondary-button {
    @apply text-base font-semibold text-[#272728] border border-[#CACACA] bg-transparent rounded-[48px] py-5 px-12 hover:bg-[#FC6D2B] hover:border-[#FC6D2B] hover:text-white transition-all duration-300;
}

.about-btn {
    .button {
        width: 180px;
        height: 68px;
    }
}