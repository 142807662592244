.hero, .breadcrumb_custom {
    background: linear-gradient(180deg, #010101 0%, #0F0E44 94.19%);
}

.react-player {

    iframe {
        border-radius: 18px;
    }
}

.change_text__animate:after{
    content: '';
    animation: animate_text infinite 20s;
}

@keyframes animate_text {
    0% {
        content: 'Cybersecurity Trainer';
    }
    15% {
        content: 'Educator';
    }
    30% {
        content: 'Engineer';
    }
    45% {
        content: 'Creative';
    }
    60% {
        content: 'Mentor';
    }
    75% {
        content: 'Speaker';
    }
    100% {
        content: 'Entrepreneur';
    }
}