.custom_after {
    position: relative;
    color: #FF8677;
    font-size: 16px;
    font-weight: 500;

}
.custom_after:after {
    position: absolute;
    content: "";
    top: 0;
    left: -60px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #E8E8E8;
    z-index: 1;
}
.custom__hover {
    padding-left: 50px;
    border-left: 1px solid #E8E8E8;
}
@media only screen and (max-width: 767px) {
    .custom__hover.custom__group__hover {
        padding-left: 25px;
    }

    .custom__hover.custom__group__hover .custom_after::after {
        left: -35px;
    }
}
.custom__hover:last-child{
    border-left: 1px solid transparent;
}
.custom__hover:hover .custom_after:after {
    background-color: #FF8677;
    transition: all 0.3s;
}
