@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Poppins', sans-serif;
}

h1,h2,h3,h4,h5,h5 {
    font-family: 'Kanit', sans-serif;;
}

a {
    text-decoration: none;
    color: inherit;
}

.pre-code::-webkit-scrollbar {
    @apply h-2.5;
}

.pre-code::-webkit-scrollbar-track {
    @apply bg-[#f4f4f4] rounded-full;
}

.pre-code::-webkit-scrollbar-thumb {
    @apply bg-[#d2d2d2] rounded-full;
}
