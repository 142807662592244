.custom_accordion {
    .accordion-item {
        @apply border-b border-[#C5C5C5] border-t-0 border-r-0 border-l-0 py-10 rounded-none;

        &:first-child {
            padding-top: 0;
        }

        .accordion-button {
            @apply bg-transparent p-0 shadow-none relative justify-between items-start sm:items-center;

            &:focus {
                @apply shadow-none;
            }

            &::after {
                display: none;
            }

            &:not(.collapsed) {
                .collapse_less {
                    @apply block;
                }

                .collapse_details {
                    @apply hidden;
                }
            }

            .collapse_icon {
                @apply text-base text-[#030308] font-medium underline;
            }

            .collapse_less {
                @apply hidden;
            }

            .collapse_details {
                @apply block;
            }


            @media only screen and (max-width: 639px) {
                flex-direction: column;
            }
        }

        .collapse_num {
            @apply text-[24px] text-[#030308] font-semibold absolute left-0 top-0;
        }

        .collapse_text {
            @apply  text-xl lg:text-[24px] text-[#030308] pl-[50px] md:pl-[120px] font-semibold;

            @media only screen and (max-width: 639px) {
                margin-bottom: 16px;
            }
        }

        .accordion-collapse {
            padding-left: 120px;
            padding-top: 16px;
            max-width: 870px;
            width: 100%;

            @media only screen and (max-width: 767px) {
                padding-left: 50px;
            }

            @media only screen and (max-width: 639px) {
                padding-left: 0;
            }
        }

        .accordion-collapse.show {
            @apply visible;
        }

        .accordion-body {
            @apply p-0;

            p {
                @apply leading-[24px] md:leading-[26px] text-[#7E7E7E] text-sm md:text-base mb-0;
            }
        }
    }
}